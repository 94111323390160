import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useNavigate} from "react-router-dom";
import Seo from "../../components/_UI/seo";


const UserAgreementPage = () => {
    const navigate = useNavigate();

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    },[])

    return (
        <section className={`${Styles.container}`}>
            <Seo title='User Agreement'/>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.title1}`}>USER AGREEMENT</div>
                <div className={`${Styles.update}`}>Last Updated: August 3, 2022</div>
                <div className={`${Styles.contentBox}`}>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                This User Agreement (this “Agreement”) is among you, Oracle Meta Technologies Unless
                                otherwise stated herein,
                                references to “Oracle Meta Technologies”, “we”, “us” or “our” in this Agreement will
                                refer collectively to
                                Oracle Meta Technologies, their respective direct and indirect subsidiaries and
                                affiliates and any entities
                                under common control with Oracle Meta Technologies. In this Agreement, “you,” “your” or
                                “User” means any
                                person or entity using the Oracle Meta Technologies Site (as defined below) or our
                                Services (as defined below)
                                and the person to whom Oracle Meta Technologies enters into this Agreement.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                This Agreement governs your use of the services provided by Oracle Meta Technologies
                                (the “Services”). By using
                                any of the Services or signing up to use an account through <span
                                className={`${Styles.link}`} onClick={() => {
                                navigate('/')
                            }}>https://omt.technology/</span> (the “Website”) or by accessing
                                or using any of our associated websites (including, without limitation, the technology
                                and the platform integrated therein),
                                APIs and/or any and all related applications (collectively, the “Other Sites” together
                                with the Website,
                                the “Oracle Meta Technologies Site”), you agree that you have read, understood and
                                accept all of the terms and
                                conditions contained in this Agreement, as well as all of the terms and conditions of
                                our <span className={`${Styles.link}`} onClick={() => {
                                navigate('/privacy-policy')
                            }}>Privacy Policy</span> which is
                                hereby incorporated by reference and forms part of this Agreement. You should read this
                                Agreement and
                                the <span className={`${Styles.link}`} onClick={() => {
                                navigate('/privacy-policy')
                            }}>Privacy Policy</span> carefully and in their entirety. This Agreement is effective as of
                                the earliest date and time
                                at which you use any of the Services or the Oracle Meta Technologies Site.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                If you do not agree to be bound by the terms and conditions of this Agreement or
                                the <span className={`${Styles.link}`} onClick={() => {
                                navigate('/privacy-policy')
                            }}>Privacy Policy</span>,
                                you must not use or access the Oracle Meta Technologies Site or use or access any of
                                Oracle Meta Technologies
                                products or Services. Any use of or access to the Oracle Meta Technologies Site or our
                                Services means you consent
                                to and agree to the terms and conditions of this Agreement and the <span
                                className={`${Styles.link}`} onClick={() => {
                                navigate('/privacy-policy')
                            }}>Privacy Policy</span>. If you do not read and accept
                                this Agreement and our <span className={`${Styles.link}`} onClick={() => {
                                navigate('/privacy-policy')
                            }}>Privacy Policy</span> in their entirety, you should not use or continue using the
                                Oracle Meta Technologies Site or our Services.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                No partnership, joint venture, employee-employer, joint associates for profit, agency or
                                franchiser- franchisee relationship
                                is intended or created by this Agreement. We do not endorse or recommend any particular
                                virtual currency, digital asset
                                or transaction. You acknowledge and agree that: (a) we are not acting as your bank,
                                broker, intermediary, agent, or
                                advisor or in any fiduciary capacity and nothing in this Agreement shall be deemed or is
                                intended to be deemed, nor
                                shall it cause, any fiduciary or advisory relationship between you and us to exist; and
                                (b) no communication or
                                information provided to you by us including, without limitation, content on the Oracle
                                Meta Technologies Site shall
                                be considered or construed as transaction, investment, tax, or any other form of advice.
                                Independent advice should
                                be sought where applicable and appropriate. You acknowledge and agree that the decision
                                to use our Services and all
                                transaction decisions are made solely by you and we bear no responsibility or liability
                                for the outcome of your decisions.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                <span className={`${Styles.bolded}`}>
                                    CAUTION: The risk of loss in using and/or holding virtual currencies can be substantial. Therefore, you should carefully
                                    evaluate whether you can bear the risk of using and/or holding virtual currencies and whether it is suitable for you.
                                    You acknowledge and agree that you shall access and use the Services at your own risk.
                                </span>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                You accept and understand that this Agreement may be modified or updated by us from time
                                to time in our sole discretion.
                                We will provide you with notice of such changes by posting the revised agreement on the
                                Website and/or providing a copy to you.
                                The amended Agreement will be deemed effective immediately upon posting on the Website.
                                Your continued use of the Site and/or
                                our Services constitutes your agreement to be bound by the revised agreement. If you do
                                not agree with any such modification,
                                your sole and exclusive remedy is to terminate your use of the Services and close your
                                account.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                Certain Services may be subject to additional terms and conditions specified by us from
                                time to time, and your use of such
                                Services is subject to those additional terms and conditions, which are hereby
                                incorporated into this Agreement by reference.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                If you have any questions about this Agreement or our Services, please contact us
                                at <span className={`${Styles.link}`} onClick={() => {
                                window.open('mailto:official@omt.technology')
                            }}>official@omt.technology</span>.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title1}`}>1. Eligibility</div>
                            <div className={`${Styles.paragraph}`}>
                                You represent and warrant that you:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(a)</div>
                                    have full power, capacity and authority to enter into this Agreement and in doing so
                                    will not violate any other agreement to which you are a party;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(b)</div>
                                    are not in non-compliance with this Agreement or the <span
                                    className={`${Styles.link}`} onClick={() => {
                                    navigate('/privacy-policy')
                                }}>Privacy Policy</span>;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(c)</div>
                                    are not on any trade or economic sanctions list, such as the UN Security Council
                                    Sanctions list or the Office of Foreign Assets Control (OFAC) list or in breach of
                                    applicable law;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(d)</div>
                                    will not use our Services if any applicable laws in your country prohibit you from
                                    doing so;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(e)</div>
                                    meet all eligibility requirements for the Services at the time of using any
                                    Services.
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                The Oracle Meta Technologies Site and our Services are also not available to persons
                                who, in our sole opinion,
                                present an unacceptable level of credit, legal or reputational risk to us, our Services
                                or to others. Any use
                                of the Oracle Meta Technologies Site or our Services by anyone who does not meet our
                                eligibility requirements
                                is strictly prohibited and in violation of this Agreement. Oracle Meta Technologies may
                                require, on an ongoing basis,
                                that you demonstrate to us that you continue to meet our eligibility requirements. Our
                                decisions with respect
                                to eligibility are final.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                In order to use the Oracle Meta Technologies Site and our Services, you must register
                                for a personal or merchant
                                account (an “Account”) and accept the terms of this Agreement and our <span
                                className={`${Styles.link}`} onClick={() => {
                                navigate('/privacy-policy')
                            }}>Privacy Policy</span>. We may, in our sole discretion,
                                refuse to open an Account for you or limit the number of Accounts that you may hold.
                                When creating your Account,
                                you must provide accurate and complete information, and you must keep this information
                                up to date. You may never use
                                another User’s account or take virtual currency or funds from another User’s account
                                without permission.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                If you open an Account on behalf of an organization, or other entity, then (i) “you”
                                includes you and that entity,
                                and (ii) you represent and warrant that you are an authorized representative of the
                                organization or entity with the
                                authority to bind the organization or entity to this Agreement, and that you agree to
                                this Agreement on the entity’s
                                or organization’s behalf.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title1}`}>2. Our Services</div>
                            <div className={`${Styles.title2}`}>2.1 Virtual Currency Services</div>
                            <div className={`${Styles.paragraph}`}>
                                Services or the services of its partners enable you to store, track, transfer, manage
                                and convert your balances
                                (collectively, “Virtual Currency Transactions”) of certain supported virtual currencies
                                in virtual currency wallets
                                hosted by Oracle Meta Technologies. We will process Virtual Currency Transactions in
                                accordance with the instructions
                                we receive from you. When you request that receive or deposit virtual currency into your
                                Account from another one
                                your accounts or wallets or request that transfer virtual currency to another wallet or
                                account from your Account,
                                you authorize to execute such transaction via the Services. Prior to submitting
                                instructions to us, you should verify
                                all transaction information. We do not guarantee the identity of any recipient, user,
                                request or other party.
                                You cannot reverse a Virtual Currency Transaction once it has been broadcast to the
                                relevant virtual currency network.
                                If a Virtual Currency Transaction has not yet been confirmed on the virtual currency
                                network, virtual currency associated
                                with such transaction will be designated as pending and will not be included in your
                                virtual currency wallet balance or
                                be available to conduct Virtual Currency Transactions. You cannot reverse or change any
                                Virtual Currency Transaction
                                marked as complete or pending.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                You must comply with all applicable laws, regulations, licensing requirements and third
                                party rights (including, without limitation,
                                data privacy laws) in your use of the Services. We may refuse to process or cancel any
                                pending Virtual Currency Transaction as
                                required by law or any court or other authority to which we are subject in any
                                jurisdiction. We further reserve the right
                                to delay any Virtual Currency Transaction if we perceive a risk of fraud or illegal
                                activity. We also have the right
                                to refuse to process or cancel any Virtual Currency Transaction due to technological
                                issues with the blockchain software,
                                our own software, or for other technological reasons.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                Our Services are available only in connection with those virtual currencies that we
                                support which may change from time to time.
                                You will not use your Account or your virtual currency wallet to store, send, request,
                                or receive virtual currencies in any form
                                that we do not support (we will use reasonable efforts to help you move or sell virtual
                                currency that we no longer support).
                                We assume no responsibility or liability in connection with any attempt to use your
                                Account or virtual currency wallet for
                                virtual currencies that we do not support.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                You agree that you will not receive interest or other earnings in your Account or
                                virtual currency wallet from the use of our
                                Services except through your own Virtual Currency Transactions and Oracle Meta
                                Technologies has no responsibility or liability
                                to you for Virtual Currency Transactions conducted by you or conducted by us in
                                accordance with your instructions. You are
                                prohibited from using our Services for any illegal or fraudulent purposes or for the
                                purpose of consummating transactions
                                for any other parties.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                All virtual currency or digital assets received by Oracle Meta Technologies into your
                                Account (“Received Assets”) are
                                not-custodial assets held by Oracle Meta Technologies for your benefit, as described in
                                further detail below:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(a)</div>
                                    <span className={`${Styles.bolded}`}>Ownership</span>. Title to Received Assets
                                    shall at all times remain with you and shall not transfer to Star Ventures, except
                                    as provided herein. As the owner of the Received Assets in your Account, you bear
                                    all risk of loss of such Received Assets and we have no responsibility or liability
                                    with respect to the value of the virtual currency or digital assets in your Account.
                                    We are under no obligation to issue any replacement virtual currency, digital assets
                                    or funds in the event that any virtual currency, digital assets, funds or password
                                    are lost, stolen, malfunctioning, destroyed or otherwise inaccessible. None of the
                                    Received Assets in your Account are the property. Except as required by a facially
                                    valid court order, or except as provided herein, Oracle Meta Technologies will not
                                    sell, transfer, loan, hypothecate or otherwise alienate Received Assets in your
                                    Account unless instructed by you or as otherwise authorized by this Agreement. For
                                    the avoidance of doubt.
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(b)</div>
                                    <span className={`${Styles.bolded}`}>Control</span>. You control the Received
                                    Assets. At any time, subject to outages, downtime, and other applicable policies or
                                    the other terms of this Agreement, you may withdraw your Received Assets by sending
                                    them to a different blockchain address controlled by you or a third party.
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(c)</div>
                                    <span className={`${Styles.bolded}`}>Wallets</span>. Your virtual currency will only
                                    be held in your wallet, managed and controlled by you.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>2.2 Internet Provider</div>
                            <div className={`${Styles.paragraph}`}>
                                Oracle Meta Technologies acts as a Service provider by creating, hosting, maintaining
                                and providing our Services to you via the Internet.
                                We cannot ensure that a buyer or a seller you are dealing with will actually complete
                                the Virtual Currency Transaction and all risk of
                                a Virtual Currency Transaction remains with you. We do not guarantee continuous,
                                uninterrupted or secure access to our Services or
                                the Oracle Meta Technologies Site and we make no representations or warranties regarding
                                the amount of time needed to complete Virtual
                                Currency Transaction processing which is dependent upon many factors outside of our
                                control. Access to Services may become degraded
                                or unavailable during times of significant volatility or volume.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>2.3 Underlying Protocols</div>
                            <div className={`${Styles.paragraph}`}>
                                We do not own, control, operate or maintain the underlying software protocols which
                                govern the operation of the virtual currencies
                                supported by us. In general, the underlying protocols are open source software and
                                anyone can use, copy, modify, and distribute them.
                                By using our Services, you acknowledge, agree and accept the risk (i) that we are not
                                responsible for the operation of the underlying
                                protocols and any changes to such protocols and we make no guarantee of their security,
                                functionality or availability; and (ii) that
                                the underlying protocols are subject to sudden changes in operating rules, which may,
                                amongst other things, materially affect the value,
                                function, name of the virtual currency and/or our ability to support certain virtual
                                currencies and we are not liable for any loss of
                                value you may experience as a result of such changes in operating rules. In the event of
                                a fork in a virtual currency, you agree that
                                we may temporarily suspend our Services (with or without advance notice to you) and that
                                we may, in our sole discretion, decide whether
                                or not to support (or cease supporting) either branch of the forked protocol entirely.
                                You also agree that in the event you send a
                                Virtual Currency Transaction at the time of a virtual currency fork, we will only honor
                                the originally-intended Virtual Currency
                                Transaction that you sent. You acknowledge and agree that we assume absolutely no
                                responsibility or liability whatsoever in respect
                                of an unsupported branch of a forked protocol.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>2.4 Identity Verification</div>
                            <div className={`${Styles.paragraph}`}>
                                During registration of your Account and at any other time you have an Account, you agree
                                to provide us with the information we request
                                for the purposes of ongoing due diligence, detection of money laundering, terrorist
                                financing, fraud, or
                                any other financial crime and permit us to keep a record of such information. You will
                                maintain and promptly update your Account
                                information. You agree and represent and warrant that all information you provide us at
                                any time will not be false, inaccurate or
                                misleading. You will need to complete certain verification procedures before you are
                                permitted to use our Services. Your access to
                                our Services and the limits that apply to your use of our Services, may be altered,
                                suspended or terminated as a result of
                                information collected about you on an ongoing basis and/or your failure to provide us
                                with information we request on a timely
                                basis. You authorize us to make inquiries, whether directly or through third parties,
                                that we consider necessary to verify your
                                identity or protect you and/or us against fraud or other financial crime, and to take
                                action we reasonably deem necessary based
                                on the results of such inquiries. When we carry out these inquiries, you acknowledge and
                                agree that your personal information
                                may be disclosed to fraud prevention or financial crime agencies and that these agencies
                                may respond to our inquiries in full.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                You are solely responsible and liable for the activity that occurs in relation to your
                                Account and for maintaining the security
                                of your Account by protecting your password and restricting access to your Account. You
                                accept all risks of any authorized or
                                unauthorized access to your Account, to the maximum extent permitted by law. You are
                                required to keep your Account password secure.
                                We recommend that you use “strong” passwords (passwords that use a combination of upper
                                and lower case letters, numbers and symbols)
                                with your Account. You must notify us immediately of any breach of security or
                                unauthorized use of your Account by emailing us at <span className={`${Styles.link}`}
                                                                                         onClick={() => {
                                                                                             window.open('mailto:official@omt.technology')
                                                                                         }}>official@omt.technology</span>.
                                We will
                                not be responsible or liable for any damages, liability or losses caused by any
                                unauthorized use of your Account.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                You may control your User profile and how you interact with our Services by changing the
                                settings in your Account settings page.
                                By providing us with your email address, you consent to our using such email address to
                                send you Service-related notices, including
                                any notices required by law, in lieu of communication by postal mail. We may also use
                                your email address to send you other messages,
                                such as changes to features of our Services. If you do not want to receive such email
                                messages, you may opt out by clicking “unsubscribe”,
                                or something similar in the email message. Opting out may prevent you from receiving
                                email messages regarding updates, improvements, or offers.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>2.5 No Warranty</div>
                            <div className={`${Styles.paragraph}`}>
                                THE ORACLE META TECHNOLOGIES SITE, ALL INFORMATION PROVIDED THROUGH THE ORACLE META
                                TECHNOLOGIES SITE AND OUR PRODUCTS AND SERVICES ARE
                                PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                EACH OF US AND OUR RESPECTIVE PAST, PRESENT AND FUTURE EMPLOYEES, OFFICERS, DIRECTORS,
                                ADVISORS, CONTRACTORS, CONSULTANTS, LICENSORS,
                                EQUITY HOLDERS, MEMBERS, PARTNERS, SHAREHOLDERS, SUPPLIERS, MANAGERS, VENDORS, SERVICE
                                PROVIDERS, PARENT COMPANIES, SUBSIDIARIES,
                                AFFILIATES, AGENTS, REPRESENTATIVES, PREDECESSORS, SUCCESSORS AND ASSIGNS (INDIVIDUALLY,
                                A “ORACLE META TECHNOLOGIES PARTY” AND COLLECTIVELY,
                                THE “ORACLE META TECHNOLOGIES PARTIES”) EXPRESSLY DISCLAIM, AND YOU WAIVE, ALL
                                WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING,
                                WITHOUT LIMITATION, IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
                                PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                No information, whether oral or written, obtained by you from us or through use of the
                                Oracle Meta Technologies Site or our Services
                                will create any warranty not expressly stated herein. Without limiting the foregoing,
                                the Oracle Meta Technologies Parties do not
                                warrant that our Services will meet your requirements; the content on the Oracle Meta
                                Technologies Site is accurate, reliable or
                                correct; that our Services will be available at any particular time or location,
                                uninterrupted, error-free or secure; that any
                                defects or errors will be corrected; or that the Oracle Meta Technologies Site is free
                                of viruses or other harmful components.
                                Use of the Oracle Meta Technologies Site and our Services are at entirely your own risk
                                and any content downloaded or otherwise
                                obtained through the use of the Oracle Meta Technologies Site or our Services is
                                downloaded at your own risk.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                The materials and related graphics on the Oracle Meta Technologies Site could include
                                technical inaccuracies or typographical
                                errors. Accordingly, you should verify all information before relying on it, and all
                                decisions based on information contained
                                on the Oracle Meta Technologies Site are your sole responsibility and we shall have no
                                liability for such decisions. Changes
                                are periodically added to the information contained on the Oracle Meta Technologies
                                Site. The Oracle Meta Technologies Parties
                                may make improvements and/or changes to the Oracle Meta Technologies Site, our products
                                and Services and/or the materials
                                described on the Oracle Meta Technologies Site at any time.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                We do or may offer features or services through third parties. We have no control over
                                and do not warrant, endorse, guarantee,
                                or assume responsibility or liability for any product or service advertised or offered
                                by a third party through the Oracle Meta
                                Technologies Site or any hyperlinked website or service, and we will not in any way
                                monitor or be a party to any transaction
                                between you and any third-party. We are not responsible or liable for ensuring that a
                                third party you transact with will
                                complete the transaction or is authorized to do so. You use third party services at your
                                own risk and you are solely responsible
                                for reviewing and understanding the implications of using the services of such third
                                parties.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>2.6 Limitation of Liability</div>
                            <div className={`${Styles.paragraph}`}>
                                To the maximum extent permitted by applicable law, in no event shall any of the Oracle
                                Meta Technologies Parties be liable for
                                any indirect, punitive, incidental, special, consequential or exemplary damages,
                                including, without limitation, damages for
                                loss of business revenues, loss of profits, goodwill, use, data or other intangible
                                losses, arising out of or relating to:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(a)</div>
                                    the use of, or inability to use, the Oracle Meta Technologies Site or our Services;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(b)</div>
                                    any information provided by us or available from the Oracle Meta Technologies Site;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(c)</div>
                                    any conduct or content of any other User of our Services or third party;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(d)</div>
                                    the failure to receive in any way the transmission of any data, content, virtual
                                    currency, funds or property from you.
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                Furthermore and without limiting the generality of the foregoing, under no circumstances
                                will any Oracle Meta Technologies
                                Party be responsible for any liability, claim, proceeding, damage, loss, expense or
                                injury resulting from hacking, tampering
                                or other unauthorized access or use of our Services or your Account or the information,
                                virtual currency or funds contained therein.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                To the maximum extent permitted by applicable law, none of the Oracle Meta Technologies
                                Parties shall be responsible or liable for any:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(a)</div>
                                    errors, mistakes, omissions or inaccuracies of information or content provided by us
                                    or on the Oracle Meta Technologies Site;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(b)</div>
                                    loss, liability, cost, expense or damage of any nature whatsoever suffered or
                                    incurred arising out of or in connection with
                                    your access to or use of the Oracle Meta Technologies Site, any of its content or
                                    our products or Services;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(c)</div>
                                    unauthorized access to or use of our secure servers and/or any and all personal
                                    information stored therein;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(d)</div>
                                    fault, delays, interruptions or lack of availability of the Oracle Meta Technologies
                                    Site or any of our Services or products
                                    provided through the Oracle Meta Technologies Site;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(e)</div>
                                    bugs, viruses, trojan horses, or the like that may be transmitted to or through our
                                    Services by any User or any third party;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(f)</div>
                                    errors or omissions in any content or for any loss or damage incurred as a result of
                                    the use of any content posted, emailed,
                                    transmitted, or otherwise made available through the Oracle Meta Technologies Site
                                    or our Services;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(g)</div>
                                    action of other Users, User Content (as defined below) or the defamatory, offensive,
                                    or illegal conduct of any third party.
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                To the maximum extent permitted by applicable law, in no event shall the Oracle Meta
                                Technologies Parties be liable to you for
                                any claims, proceedings, liabilities, obligations, expenses, damages, losses or costs in
                                an amount exceeding the amount of fees
                                paid by you to Oracle Meta Technologies for the applicable Services for the 12 months
                                preceding the date of any claim giving rise
                                to such liability or one hundred U.S. dollars (USD $100.00), whichever is greater.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                This limitation of liability section applies whether the alleged liability is based on
                                contract, tort, negligence, strict liability,
                                or any other basis, even if a Oracle Meta Technologies Party has been advised of the
                                possibility of such damage. The foregoing
                                limitation of liability shall apply to the fullest extent permitted by law in the
                                applicable jurisdiction.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                Some jurisdictions do not allow the exclusion of certain warranties or the limitation or
                                exclusion of liability for incidental or
                                consequential damages. Accordingly, some of the limitations of this section may not
                                apply to you.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>2.7 Indemnification</div>
                            <div className={`${Styles.paragraph}`}>
                                You agree to defend, indemnify and hold harmless each Oracle Meta Technologies Party
                                from and against any and all claims, proceedings,
                                damages, obligations, losses, liabilities, costs or debt, and expenses (including but
                                not limited to attorney’s fees) arising from:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(a)</div>
                                    your use of and access to the Oracle Meta Technologies Site and our Services,
                                    including, without limitation, any data, virtual
                                    currency or content transmitted or received by you;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(b)</div>
                                    your violation of any term or condition of this Agreement, including without
                                    limitation, your breach of any of the representations
                                    and warranties contained herein;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(c)</div>
                                    your violation of any third-party right, including, without limitation, any right of
                                    privacy or Intellectual Property Rights
                                    (as defined below);
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(d)</div>
                                    your violation of any applicable law, rule or regulation;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(e)</div>
                                    your User Content or any content that is submitted via your Account including,
                                    without limitation, misleading, false, or
                                    inaccurate information;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(f)</div>
                                    your fraudulent behaviour, willful misconduct or gross negligence;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(g)</div>
                                    any other party’s access and use of your Account or our Services with your unique
                                    username, password or other appropriate
                                    security code.
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                If you are obligated to indemnify us, we will have the right, in our sole discretion, to
                                control any action or proceeding and
                                determine whether we wish to settle it. In such case, you will share the costs of our
                                expenses.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title1}`}>3. Receiving Payments</div>
                            <div className={`${Styles.title2}`}>3.1 Funding of Payments</div>
                            <div className={`${Styles.paragraph}`}>
                                You are responsible for maintaining an adequate balance and sufficient proceeds in your
                                Account in order to pay for fees and
                                complete transactions and in order to avoid overdraft, insufficient funds, or similar
                                fees being charged.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>3.2 Withdrawals</div>
                            <div className={`${Styles.paragraph}`}>
                                You may withdraw virtual currency from your Account;
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title1}`}>4. Sending Payments.</div>
                            <div className={`${Styles.title2}`}>4.1 Sending Payments</div>
                            <div className={`${Styles.paragraph}`}>
                                You can send virtual currency from your external virtual currency addresses to any
                                external virtual currency address,
                                depending on the selected measure or block.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title1}`}>5. Refund Policy</div>
                            <div className={`${Styles.title2}`}>5.1 Completed Transactions</div>
                            <div className={`${Styles.paragraph}`}>
                                It is the nature of Bitcoin, Litecoin, and the other virtual currencies that we support
                                that all Virtual Currency
                                Transactions are final with no method of refunding, charging back or other recourse for
                                the sender of the virtual
                                currency. As such we are unable to cancel, reverse or provide refunds for any Virtual
                                Currency Transaction made
                                through our Services. We do provide a feedback system so buyers can leave ratings for
                                sellers (and vice versa).
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>5.2 Sent to wrong coin/chain, missing tags, delisted
                                coins, etc
                            </div>
                            <div className={`${Styles.paragraph}`}>
                                If you send Virtual Currency to the wrong Virtual Currency, Virtual Currency Wallet or
                                Blockchain, or de-asseted
                                Virtual Currency, you are solely responsible for that transfer and we will not accept
                                any liability or reimbursement
                                for damages or losses for that transaction.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>5.3 No Liability for Errors/Omissions</div>
                            <div className={`${Styles.paragraph}`}>
                                You accept and acknowledge that we are not liable or responsible for any errors or
                                omissions that are made in
                                connection with any Virtual Currency Transaction initiated via the Services. We strongly
                                encourage you to
                                review your transaction details carefully before attempting to transfer a virtual
                                currency.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title1}`}>6. Prohibited Activities</div>
                            <div className={`${Styles.title2}`}>6.1 Prohibited Activities</div>
                            <div className={`${Styles.paragraph}`}>
                                You agree not to engage in any of the following prohibited activities which may be
                                amended
                                by us from time to time in our sole discretion (“Prohibited Activities”). The specific
                                types
                                of prohibited activities listed below are illustrative, but not exhaustive. If you are
                                uncertain
                                as to whether or not your use of our Services involves a Prohibited Activity, please
                                contact <span className={`${Styles.link}`} onClick={() => {
                                window.open('mailto:official@omt.technology')
                            }}>official@omt.technology</span> as soon
                                as possible. By accessing and using the Oracle Meta Technologies
                                Site and our Services and/or opening an Account, you agree that you will not violate any
                                law,
                                contract, intellectual property or other third-party right or commit a tort, and that
                                you are
                                solely responsible for your conduct while using our Services. Without limiting the
                                generality
                                of the foregoing, you confirm that you will not use your Account, the Oracle Meta
                                Technologies
                                Site or our Services in any manner to do any of the following:
                            </div>
                            <div className={`${Styles.paragraphList} ${Styles.paragraphListLetter}`}>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(a)</div>
                                    <span className={`${Styles.bolded}`}>Unlawful Activity</span>. Be, support or send
                                    payment
                                    to an individual or entity sanctioned under sanctions programs administered in the
                                    countries
                                    where we carry on business, including, but not limited to, European Union law
                                    sanction programs
                                    and sanctions programs administered by the United Nations (collectively, the
                                    “Sanctions Programs”);
                                    carry on activity which would violate, or assist in violation of, any law,
                                    legislation, statute,
                                    ordinance, regulation (including, but not limited to, those governing financial
                                    services,
                                    money laundering, consumer protection, unfair competition, anti-discrimination, or
                                    false advertising)
                                    or Sanctions Program, or which would involve proceeds of any unlawful activity;
                                    publish, distribute
                                    or disseminate any illegal material or information; commit fraud; money laundering;
                                    terrorist activities;
                                    be in violation of any court order; or any other illegal activities.

                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(b)</div>
                                    <span className={`${Styles.bolded}`}>Unlawful Businesses</span>. Carry on any of
                                    following businesses or
                                    activities: (i) unlawful pornography and the unlawful creation, sale or distribution
                                    of other obscene
                                    materials (including literature, imagery and other media) and sexually-related sites
                                    offering services
                                    such as prostitution, escorts, pay-per view and adult live chat features; (ii)
                                    unlawful gambling
                                    and/or unlawful gaming activities, including but not limited to payment or the
                                    acceptance of payments
                                    for wagers, gambling debts or gambling winnings, regardless of the location or type
                                    of gambling activity
                                    (including online and offline casinos, sports wagering, lotteries, bidding fee
                                    auctions and office pools)
                                    with the exception of payments for online gaming transactions that are expressly
                                    authorized by law in the
                                    jurisdiction of both the sender and the recipient of the payment; (iii) fraudulent
                                    businesses, sale of
                                    counterfeit or unauthorized or stolen items or the sale of goods or services that
                                    are illegally imported
                                    or exported; (iv) marijuana dispensaries and related businesses where it is illegal
                                    to carry on such
                                    businesses; sale of tobacco, e-cigarettes, and e-liquid; online prescription or
                                    pharmaceutical services;
                                    age restricted goods or services; weapons and munitions; gunpowder and other
                                    explosives; fireworks and
                                    related goods; or toxic, flammable, and radioactive materials; (v) sale of narcotics
                                    or illegal substances,
                                    and any equipment designed for making or using such drugs; (vi) ponzi schemes,
                                    pyramid schemes, high risk
                                    investment schemes and other businesses that we determine in our sole discretion to
                                    be unfair, deceptive,
                                    or predatory towards consumers; and (vii) any businesses that we believe poses
                                    elevated financial risk,
                                    legal liability, or violates the law.
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(c)</div>
                                    <span className={`${Styles.bolded}`}>Abusive Activity</span>. Transmit or upload any
                                    material to the Oracle
                                    Meta Technologies Site that contains viruses, trojan horses, worms, time bombs,
                                    cancelbots or any other harmful
                                    or deleterious programs or other computer programming routines that may damage,
                                    detrimentally interfere with,
                                    surreptitiously intercept or expropriate any system, data or other personal
                                    information; actions which impose
                                    an unreasonable or disproportionately large load on our infrastructure, or
                                    detrimentally interferes with,
                                    intercepts, or expropriates any system, data, or information; attempt to gain
                                    unauthorized access to the
                                    Oracle Meta Technologies Site, other Users’ accounts, computer systems or networks
                                    connected to the
                                    Oracle Meta Technologies Site, through password mining or any other means; use
                                    account information of another
                                    party to access or use the Oracle Meta Technologies Site; transfer your Account
                                    access or rights to your Account
                                    to a third party, unless by operation of law or with our express permission; any
                                    activity which creates costs,
                                    expenses, losses or liability for us or causes us to lose (in whole or in part) the
                                    services of our ISPs or other
                                    suppliers; activity which operates to defraud us, our Users, or any other person
                                    including, without, limitation,
                                    take funds or virtual currency from our platform or from other Users that is not
                                    rightfully and legally yours or
                                    overdraw or remove more virtual currency or funds from our platform than was
                                    legitimately in your Account; modify,
                                    disassemble, decompile or reverse engineer our Services; use any robot, spider,
                                    other automatic device, or manual
                                    process to monitor or copy our web pages or the content contained herein without our
                                    prior express written permission;
                                    provide any false, inaccurate, or misleading information to us; transmit spam, chain
                                    letters, or other unsolicited email;
                                    attempt to interfere with, compromise the system integrity or security or decipher
                                    any transmissions to or from the
                                    servers running our Services; impersonate another person or otherwise misrepresent
                                    your affiliation with a person or
                                    entity, conduct fraud, hide or attempt to hide your identity; use any device,
                                    software or routine to bypass our robot
                                    exclusion headers, or to interfere or attempt to interfere with the proper working
                                    of the Oracle Meta Technologies Site
                                    or any activities conducted on the Oracle Meta Technologies Site or our Services;
                                    access any content on the
                                    Oracle Meta Technologies Site through any technology or means other than those
                                    provided or authorized by us;
                                    bypass the measures we may use to prevent or restrict access to our Services,
                                    including without limitation remove,
                                    circumvent, disable or otherwise interfere with security-related features or
                                    features that prevent or restrict use
                                    or copying of any content or enforce limitations on the use of the Oracle Meta
                                    Technologies Site or the content therein;
                                    damage, disable, overburden or impair the functioning of our Services in any manner;
                                    or delete our copyright or other
                                    proprietary rights notices.
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(d)</div>
                                    <span className={`${Styles.bolded}`}>Abuse Of Others</span>. Be defamatory, abusive,
                                    extortionist, trade libelous,
                                    unlawfully threatening or harassing or otherwise violate or infringe the legal
                                    rights (such as, but not limited to,
                                    rights of privacy, publicity and intellectual property) of others; interfere with
                                    another individual’s or entity’s
                                    access to or use of the Oracle Meta Technologies Site or any of our Services;
                                    interfere with, disrupt, negatively
                                    affect or inhibit other users from fully enjoying our Services; incite, threaten,
                                    facilitate, promote, or encourage
                                    hate, racial intolerance, or violent acts against others; harvest or otherwise
                                    collect information from the Oracle
                                    Meta Technologies Site about others, including without limitation email addresses,
                                    without proper consent.
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    <div className={`${Styles.absMarker}`}>(e)</div>
                                    <span className={`${Styles.bolded}`}>Intellectual Property Infringement</span>.
                                    Engage in transactions which violate,
                                    infringe or misappropriate any intellectual or industrial property right of any
                                    person (such as copyright, trademarks,
                                    patents, or trade secrets, or other proprietary rights of any party), right of
                                    publicity or privacy or commit a tort;
                                    use of our intellectual property, name, or logo, including use of the Oracle Meta
                                    Technologies trade or service marks,
                                    without express consent from us or in a manner that otherwise harms us or our brand;
                                    create a false identity for the
                                    purpose of misleading others or fraudulently or otherwise misrepresent yourself to
                                    be another person or a representative
                                    of another entity including, but not limited to, an authorized user of the Oracle
                                    Meta Technologies Site or our
                                    representative, or fraudulently or otherwise misrepresent that you have an
                                    affiliation with a person, entity or group.
                                </div>
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                We reserve the right at all times to monitor, review, retain and/or disclose any
                                information as necessary to satisfy any
                                applicable law, regulation, Sanctions Programs, legal process, or governmental request.
                                We reserve the right to cancel
                                and/or suspend your Account and/or block transactions or freeze funds immediately and
                                without notice if we determine,
                                in our sole discretion, that your Account is associated with a Prohibited Activity.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                Our Services are not available in every jurisdiction and we reserve the right to select
                                the markets and jurisdictions
                                where we provide Services. Persons and entities in jurisdictions where we do not provide
                                Services are prohibited from using
                                or accessing the Oracle Meta Technologies Site and any of our Services.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>6.2 License</div>
                            <div className={`${Styles.paragraph}`}>
                                If you post, upload, input, provide or submit your personal data to us, including
                                without limitation, your name, email address,
                                IP address, cryptocurrency address, text, code or other information and materials, sign
                                up to our mailing list or create an
                                Account (collectively, your “User Content”), you must ensure that the User Content
                                provided by you at that or at any other
                                time is true, accurate, up to date and complete and that any User Content you post,
                                upload, input, provide or submit to us
                                or via the Oracle Meta Technologies Site does not breach or infringe the intellectual
                                property rights of any third party.
                                We do not own, control or endorse any User Content that is transmitted, stored or
                                processed via the Oracle Meta Technologies Site
                                or sent to us. You are solely responsible and liable for all of your User Content and
                                for your use of any interactive features,
                                links or information or content on the Oracle Meta Technologies Site, and you represent
                                and warrant that (i) you own all
                                intellectual property rights (or have obtained all necessary permissions) to provide
                                your User Content and to grant the
                                licenses in this Agreement and (ii) your User Content will not violate, infringe or
                                misappropriate any intellectual
                                property right or other proprietary right, including the right of publicity or privacy,
                                of any person or entity.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                You are responsible for maintaining the confidentiality of your User Content and any of
                                your non- public information.
                                Furthermore, you are entirely responsible for any and all activities that occur under
                                your Account. You agree to notify
                                us immediately of any unauthorized use of your User Content, Account or any other breach
                                of security. We will not be
                                responsible or liable for any loss or damages that you may incur as a result of someone
                                else using your User Content
                                or Account, either with or without your knowledge. However, you could be held liable for
                                losses, expenses and costs
                                incurred by any Oracle Meta Technologies Party or another party due to someone else
                                using your User Content or Account.
                                You may not use anyone else’s User Content or account at any time without the permission
                                of such person or entity.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                By posting, uploading, inputting, providing or submitting your User Content to us, you
                                grant us and any necessary
                                sub-licensees a non-exclusive, worldwide, perpetual, right and permission to use,
                                reproduce, copy, edit, modify,
                                translate, reformat, create derivative works from, distribute, transmit, publicly
                                perform and publicly display your
                                User Content and sub-license such rights to others.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                You must immediately update and inform us of any changes to your User Content by
                                updating your personal data by
                                contacting us at <span className={`${Styles.link}`} onClick={() => {
                                window.open('mailto:official@omt.technology')
                            }}>official@omt.technology</span> so that we can communicate with you effectively and
                                provide accurate and
                                up to date information to you.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                Although we have no obligation to screen, edit or monitor User Content, we reserve the
                                right, and have absolute
                                discretion, to remove, screen or edit User Content. Furthermore, if we have reason to
                                believe that there is likely
                                to be a breach of security, breach or misuse of the Oracle Meta Technologies Site or if
                                you breach any of your
                                obligations under this Agreement or our <span className={`${Styles.link}`}
                                                                              onClick={() => {
                                                                                  navigate('/privacy-policy')
                                                                              }}>Privacy Policy</span>, we may suspend
                                your use of the Oracle Meta Technologies Site
                                at any time and for any reason.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                Any User Content submitted by you on the Oracle Meta Technologies Site may be accessed
                                by us globally.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>6.3 Our Proprietary Rights</div>
                            <div className={`${Styles.paragraph}`}>
                                Except for your User Content, the Oracle Meta Technologies Site and all materials
                                therein or transferred thereby, including, without limitation,
                                software, technology, code, images, text, graphics, illustrations, logos, patents,
                                trademarks, service marks, copyrights, photographs, audio,
                                videos, music, and User Content belonging to other Users (the “Our Content”), and all
                                Intellectual Property Rights (defined below) related thereto,
                                are our exclusive property and our licensors (including other Users who post User
                                Content to the Oracle Meta Technologies Site). Except as
                                explicitly provided herein or in any applicable additional terms and conditions, nothing
                                in this Agreement shall be deemed to create a license
                                in or under any such Intellectual Property Rights, and you agree not to sell, modify,
                                reverse engineer, license, rent, distribute, copy, reproduce,
                                transmit, publicly display, publicly perform, publish, adapt, edit or create derivative
                                works from any of Our Content, absent specific consent in
                                writing from us. Use of Our Content for any purpose not expressly permitted by this
                                Agreement is strictly prohibited.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                For the purposes of this Agreement, “Intellectual Property Rights” means all patent
                                rights, copyright rights, mask work rights, moral rights,
                                rights of publicity, trademark, trade dress and service mark rights, goodwill, trade
                                secret rights and other intellectual property rights as may
                                now exist or hereafter come into existence, and all applications therefore and
                                registrations, renewals and extensions thereof, under the laws of
                                any state, country, territory or other jurisdiction.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                You may choose to or we may invite you to submit comments or ideas about our Services,
                                including without limitation about how to improve our
                                Services or our products (“Ideas”). By submitting any Ideas, you agree that your
                                disclosure is gratuitous, unsolicited and without restriction
                                and will not place us under any fiduciary or other obligation, and that we are free to
                                use the Ideas without any additional compensation to you,
                                and/or to disclose the Ideas on a non-confidential basis or otherwise to anyone. You
                                further acknowledge that, by acceptance of your submission,
                                we do not waive any rights to use similar or related ideas previously known to us, or
                                developed by any of the Oracle Meta Technologies Parties,
                                or obtained from sources other than you.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>6.4 Third Party Content</div>
                            <div className={`${Styles.paragraph}`}>
                                We may display third-party content, advertisements, links, promotions, logos and other
                                materials on the Oracle Meta Technologies Site (collectively,
                                the “Third-Party Content”) for your convenience only. We do not approve of, control,
                                endorse or sponsor any third parties or Third- Party Content,
                                and we make no representations or warranties of any kind regarding such Third- Party
                                Content, including, without limitation, the accuracy, validity,
                                legality, copyright compliance, or decency of such content. If you access a third-party
                                website or service from the Oracle Meta Technologies Site or
                                share your User Content on or through any third-party website or service, you do so at
                                your own risk, and you understand that this Agreement and our
                                <span className={`${Styles.link}`} onClick={() => {
                                    navigate('/privacy-policy')
                                }}>Privacy Policy</span> do not apply to your use of such sites. You expressly relieve
                                the Oracle Meta Technologies Parties from any and all responsibility and
                                liability arising from your use of any third-party website, service, or content,
                                including without limitation User Content submitted by other Users.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title1}`}>7. Privacy and Security</div>
                            <div className={`${Styles.title2}`}>7.1 Privacy</div>
                            <div className={`${Styles.paragraph}`}>
                                We respect the privacy of our Users and we only request information that is necessary
                                for the use of our Services or to comply with our obligations
                                under applicable law.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                You acknowledge and accept that we will comply willingly with all legal requests for
                                information from us. We reserve the right to provide information
                                to law enforcement personnel and other third parties to answer inquiries, participate in
                                investigations, respond to legal process, respond to the order
                                of a court of competent jurisdiction and those exercising the court’s authority, and to
                                protect us and our Users.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                You understand that by using our Services you consent to the collection, use and
                                disclosure of your personally identifiable information and aggregate
                                data as set forth in our <span className={`${Styles.link}`} onClick={() => {
                                navigate('/privacy-policy')
                            }}>Privacy Policy</span> and to your personally identifiable information being collected,
                                used, transferred to and processed in
                                jurisdictions where we carry on business. By agreeing to this Agreement, you expressly
                                allow us to export data outside of the jurisdiction in which
                                you reside or are located when you access our Services. You acknowledge that we may
                                process personal data in relation to you (if you are an individual),
                                and personal data that you have provided or in the future provide to us in relation to
                                your entity, employees or other associates, in connection with
                                this Agreement, or our Services. Accordingly, you represent and warrant that:
                            </div>
                            <div className={`${Styles.paragraphList}`}>
                                <div className={`${Styles.paragraphListItem}`}>
                                    before providing any such personal data to us, you have read and understood
                                    our <span className={`${Styles.link}`} onClick={() => {
                                    navigate('/privacy-policy')
                                }}>Privacy Policy</span> which may be amended from time to time and is
                                    incorporated by reference and forms part of this Agreement and have provided a copy
                                    to any individual whose personal data you have provided to us;
                                </div>
                                <div className={`${Styles.paragraphListItem}`}>
                                    from time to time if we amend the <span className={`${Styles.link}`}
                                                                            onClick={() => {
                                                                                navigate('/privacy-policy')
                                                                            }}>Privacy Policy</span>, you will promptly
                                    read it and provide a copy to any individual whose personal data you have provided
                                    to us.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>7.2 Security</div>
                            <div className={`${Styles.paragraph}`}>
                                We care about the integrity and security of your personal information and we take a
                                number of security measures including storing all Users’ personal
                                information in an encrypted fashion. However, we cannot guarantee that unauthorized
                                third parties will not defeat our security measures or use your
                                personal information for improper purposes. You acknowledge that you provide your
                                personal information to us at your own risk.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                We are not responsible or liable for any damage or interruptions caused by any computer
                                viruses, spyware, scareware, Trojan horses, worms or other
                                malware that may affect your computer or other equipment, or any phishing, spoofing or
                                other attack from using the Oracle Meta Technologies Site.
                                We advise the regular use of a reputable and readily available virus screening and
                                prevention software. You should also be aware that SMS and email
                                services are vulnerable to spoofing and phishing attacks and should use care in
                                reviewing messages purporting to originate from us. Our customer
                                support will never seek to access your computer or ask for your password or your two
                                factor authentication codes. Always log into your Account
                                through the official Website to review any transactions or required actions if you have
                                any uncertainty regarding the authenticity of any communication.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                By using the Honee AI platform, you acknowledge and accept that all responsibility and
                                 any risks associated with participating in the game rest solely with you. Honee AI is
                                  a gaming platform that uses the HONEE in-game currency. By purchasing this in-game
                                   currency, you agree to comply with the applicable game rules.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                We reserve the right, at our sole discretion, to refuse to process any exchange of
                                 in-game currency (in any direction) without providing a reason. Your use of any
                                  features of the platform constitutes your acceptance of these terms.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title1}`}>8. Closing, Suspension, Termination and Remedies</div>
                            <div className={`${Styles.title2}`}>8.1 Closing Your Account and Unclaimed Property</div>
                            <div className={`${Styles.paragraph}`}>
                                You can close your deposit in accordance with the terms specified in the white paper.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title1}`}>9. General Contract Terms</div>
                            <div className={`${Styles.title2}`}>9.1 Limited License</div>
                            <div className={`${Styles.paragraph}`}>
                                We grant you a non-exclusive, limited, non-transferable, freely revocable license,
                                subject to the terms of this Agreement, to access and use the Oracle
                                Meta Technologies Site, and related content, materials, information (collectively, the
                                “Content”) solely for approved purposes as permitted by us from
                                time to time. Any other use of the Oracle Meta Technologies Site or Content is expressly
                                prohibited and all other right, title, and interest in
                                the Oracle Meta Technologies Site or Content is exclusively the property of us and our
                                licensors. You agree not to copy, transmit, distribute, sell,
                                license, reverse engineer, modify, publish, or participate in the transfer or sale of,
                                create derivative works from, or in any other way exploit any
                                of the Content, in whole or in part. “Oracle Meta Technologies”, “UABSV”, “UAB Star
                                Ventures”, “Star Ventures” and all logos related to our Services
                                or displayed on the Oracle Meta Technologies Site are our or our licensors’ trademarks
                                or registered marks. You may not copy, imitate or use them
                                without our prior written consent.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>9.2 Assignment and Novation</div>
                            <div className={`${Styles.paragraph}`}>
                                This Agreement is personal to you and you cannot transfer, assign, novate or delegate
                                your rights, licenses, interests, liabilities and/or obligations
                                to anyone else. You irrevocably agree that we are entitled to and may, at any time,
                                transfer, assign, novate or delegate any or all of our rights,
                                title, licenses, interests, benefits, assets (including wallets), liabilities and/or our
                                obligations under this Agreement and/or under any other
                                agreement, document, and/or assurance in connection therewith and/or in connection with
                                your Account(s) and/or any services available thereunder,
                                in whole or in part, to any party, including, without limitation, as part of a merger,
                                asset transfer, acquisition or other corporate reorganisation
                                involving us, without obtaining your consent, permission or approval and in any way we
                                consider appropriate. You hereby irrevocably agree to any such
                                transfer, assignment, novation or delegation of this Agreement and any other agreement,
                                document, and assurance in connection therewith or with your
                                Account(s) with us and/or any services available thereunder or securing your obligations
                                thereunder and you also irrevocably agree to enter into all
                                necessary documentation to give effect to any such transfer, assignment, novation and/or
                                delegation.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>9.3 Force Majeure</div>
                            <div className={`${Styles.paragraph}`}>
                                We shall not be liable for any breach of the Agreement, including delays, failure in
                                performance or interruption of service, or any loss or damage
                                arising directly or indirectly from any event or circumstances beyond our reasonable
                                control, including but not limited to flood, extraordinary
                                weather conditions, earthquake, or other act of God, fire, war, insurrection, riot,
                                labor dispute, accident, action of government, communications,
                                pandemic, power failure, or equipment or software malfunction, changes in the law or any
                                other cause beyond our reasonable control
                                (each, a "Force Majeure Event").
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>9.4 Entire Agreement</div>
                            <div className={`${Styles.paragraph}`}>
                                This Agreement (including the <span className={`${Styles.link}`} onClick={() => {
                                navigate('/privacy-policy')
                            }}>Privacy Policy</span> and any other documents incorporated by reference herein) comprise
                                the entire agreement between you and
                                us as to the subject matter hereof, and it supersedes any and all prior discussions,
                                agreements and understandings of any kind
                                (including without limitation any prior versions of this Agreement), and every nature
                                between and among you and us. This Agreement will bind and inure
                                to the benefit of the parties to this Agreement and their respective successors,
                                assigns, heirs, executors, administrators and legal representatives.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>9.5 Interpretation</div>
                            <div className={`${Styles.paragraph}`}>
                                Section headings in this Agreement are for convenience only and shall not govern the
                                meaning or interpretation of any provision of this Agreement.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>9.6 Invalidity</div>
                            <div className={`${Styles.paragraph}`}>
                                Any provision of this Agreement which may be determined by competent authority to be
                                prohibited or unenforceable in any jurisdiction shall,
                                as to such jurisdiction, be ineffective to the extent of such prohibition or
                                unenforceability without invalidating the remaining provisions hereof,
                                and any such prohibition or unenforceability in any jurisdiction shall not invalidate or
                                render unenforceable such provision in any other jurisdiction.
                                In such case, the parties to this Agreement shall in good faith modify or substitute
                                such provision consistent with the original intent of the parties.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>9.7 Enforcement of Our Rights</div>
                            <div className={`${Styles.paragraph}`}>
                                Our failure to enforce any threatened or existing violation, default or breach of this
                                Agreement shall not be deemed a waiver of such a violation,
                                default or breach, and we shall have the right to enforce the same at a later time and
                                the right to waive in writing any provision or condition
                                imposed herein for its benefit without thereby waiving any other provision or condition.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>9.8 Survival</div>
                            <div className={`${Styles.paragraph}`}>
                                All provisions of this Agreement which by their nature extend beyond the expiration or
                                termination of this Agreement, including, without limitation,
                                the sections relating to suspension or termination, Account cancellation, debts owed to
                                Oracle Meta Technologies, general use of the
                                Oracle Meta Technologies Site, and general provisions will continue to be binding and
                                operate after the termination or expiration of this Agreement
                                or your Account.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>9.9 Electronic Communications</div>
                            <div className={`${Styles.paragraph}`}>
                                You agree that this Agreement constitutes “a writing signed by you” under any applicable
                                law or regulation. This Agreement, any amendments and
                                any other agreements, notices or other communications regarding your Account and/or your
                                use of our Services (collectively, the “Communications”)
                                may be provided to you electronically and you agree to receive all Communications from
                                us in electronic form. Electronic Communications may be
                                posted on the pages of the Oracle Meta Technologies Site and/or delivered to your e-mail
                                address. You may print a copy of any Communications
                                and retain it for your records. All Communications in either electronic or paper format
                                will be considered to be in “writing,” and to have
                                been received no later than two (2) business days after posting or dissemination,
                                whether or not you have received or retrieved the Communication.
                                We reserve the right but assume no obligation to provide Communications in paper format.
                            </div>
                        </div>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.paragraph}`}>
                                It is your responsibility to keep your email address and street address on file with us
                                up to date so that we can communicate with you electronically.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>9.10 Applicable Law</div>
                            <div className={`${Styles.paragraph}`}>
                                This Agreement shall be governed in all respects, including as to validity,
                                interpretation and effect, by English law. The application of the United Nations
                                Convention on Contracts for the International Sale of Goods is expressly excluded.
                            </div>
                        </div>
                    </div>
                    <div className={`${Styles.contentBoxItem}`}>
                        <div className={`${Styles.paragraphBox}`}>
                            <div className={`${Styles.title2}`}>9.11 Credit Report</div>
                            <div className={`${Styles.paragraph}`}>
                                You agree that we may obtain and review your credit report for the purpose of assessing
                                your suitability to open an Account and/or use any of our Services.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UserAgreementPage;