
export const api = {
    host:  process.env.REACT_APP_API_URL,
    // host:  'http://api.olimp.alphadevteam.net/',
    // host:  'https://api.h.adtdev.pro/',
    // host:  'https://api.honee.ai/',
    login: 'api/v1/login',
    loginWH: 'api/v1/login-via-code',
    registration: 'api/v1/register',
    registrationWallet: 'api/v1/connect-honee-wallet',
    logout: 'api/v1/logout',
    about: 'api/v1/ol/about',
    contact: 'api/v1/support',
    forgotPassword: 'api/v1/forgot-password',
    resetPassword: 'api/v1/reset-password',
    latestNews: 'api/v1/posts/latest',
    allNews: 'api/v1/posts/paginated',
    post: 'api/v1/posts',
    phrase: 'wallet-api/v1/backup-phrase',
    user: 'api/v1/auth/user',
    server: 'api/v1/servers',
    omtPrice: 'api/v1/omt-rate',
    cabinet: {
        user: 'api/v1/user',
        wallets: 'api/v1/wallets',
        partners: 'api/v1/referral-programs',
        partnersDashboard: 'api/v1/partners-dashboard',
        getDeposit: 'api/v1/deposits/cabinet',
        deposit: 'api/v1/deposits',
        topup: 'api/v1/top-up',
        withdraw: 'api/v1/withdraw',
        userDeposit: 'api/v1/user-deposits',
        transactions: 'api/v1/transactions',
        currencies: 'api/v1/currencies',
        stats: 'api/v1/ol/stats',
        referrals: 'api/v1/referrals/paginated',
        help: 'api/v1/support',
        changePassword: 'api/v1/settings/change-password',
        invest: 'api/v1/user-deposits',
        botProfit: 'api/v1/bot/months-stats',
        setGreeting: 'api/v1/greeting-displayed',
        omtToken: 'api/v1/omt20-token/wallet',
        promotionsNY: 'api/v1/promotions/new-year/main',
        spinNY: 'api/v1/promotions/new-year/wheel-of-fortune',
        historyNY: 'api/v1/promotions/new-year/wheel-of-fortune/logs',
        packNY: 'api/v1/promotions/new-year/wheel-of-fortune/packs'
    },
    bot: {
        main: 'api/v1/bot/main',
        open: 'api/v1/bot',
        show: 'api/v1/bot/show',
        subscribe: 'api/v1/bot-subscriptions/available',
        custom: 'api/v1/bot/custom-settings',
        buySubscribe: 'api/v1/bot-subscriptions/buy',
        history: 'api/v1/deal-history'
    },
    settings: {
        password: 'api/v1/auth/user/password'
    },
    support:{
        main: 'api/v1/support'
    },
    bonuses: {
        main: 'api/v1/referral/main',
        info: 'api/v1/referral-info',
        logs: 'api/v1/referral/logs/stats'
    },
    ambassador: {
        main: 'api/v1/ambassadors/main',
        statuses: 'api/v1/ambassadors/statuses',
        graph: 'api/v1/ambassadors/graph',
        links: 'api/v1/ambassadors/attach-links',
        turnover: 'api/v1/ambassadors/turnover',
        salary: 'api/v1/ambassadors/salaries'
    },
    honeechize: {
        honeechizeMain: 'api/v1/honeechize/info',
        honeechizeEarningsGraph: 'api/v1/honeechize/earnings-graph',
        honeechizeProfitGraph: 'api/v1/honeechize/profit-forecast',
        honeechizeAffiliateSettings: 'api/v1/honeechize/settings',
        honeechizeAffiliateStatistic: 'api/v1/honeechize/referral-stats',
        honeechizeBotEarnings: 'api/v1/honeechize/earnings',
        honeechizeBotSettings: 'api/v1/honeechize/bot-settings'
    },
    index: {
        indexMain: 'api/v1/crypto-index/omt20/main',
        indexSwap: 'api/v1/crypto-index/omt20/buy',
        indexHistory: 'api/v1/crypto-index/omt20/history',
        indexPrediction: 'api/v1/crypto-index/omt20/comparison'
    },
    walletApi: {
        indicators: 'wallet-api/v1/currencies',
        availableCurrencies: 'wallet-api/v1/currencies/available',
        createWallet: 'wallet-api/v1/wallets',
        transactionCommission: 'wallet-api/v1/estimate',
        historyCurrencies: 'wallet-api/v1/currencies',
        periods: 'wallet-api/v1/omt-periods',
        transactions: 'wallet-api/v1/transactions',
        staking: 'wallet-api/v1/staking',
        stakingOpen: 'wallet-api/v1/staking/omt',
        stakingMain: 'wallet-api/v1/staking/main',
        stakingCommission: 'wallet-api/v1/estimate',
        subscribeCommission: 'wallet-api/v1/estimate/omt',
        stakingWithdraw: 'wallet-api/v1/omt-staking',
        exchange: 'wallet-api/v1/honey/exchanger',
        exchangeHistory: 'wallet-api/v1/exchanger-logs',
        exchangeCommission: 'wallet-api/v1/estimate',
        activeFreeze: 'wallet-api/v1/user/staking/omt20',
        reinvestFreeze: 'wallet-api/v1/staking/user',
        withdrawFreeze: 'wallet-api/v1/staking',
        totalFreeze: 'wallet-api/v1/staking/omt20/totals',
        swapMain: 'wallet-api/v1/dex/omt20/main',
        swapCommission: 'wallet-api/v1/estimate/dex',
        swapBuyRequest: 'wallet-api/v1/dex/omt20/create/buy',
        swapSellRequest: 'wallet-api/v1/dex/omt20/create/sell',
        mySwapRequests: 'wallet-api/v1/dex/omt20/requests/my',
        allSwapRequests: 'wallet-api/v1/dex/omt20/requests',
        cancelMySwapRequest: 'wallet-api/v1/dex',
        swapConfirmBuyRequest: 'wallet-api/v1/dex',
        swapConfirmSellRequest: 'wallet-api/v1/dex',
        nyCoinsCommission: 'wallet-api/v1/estimate/omt-packs'
    },
    news:{
        latest: 'api/v1/posts/latest',
        post: 'api/v1/posts',
        top: 'api/v1/posts/latest?top=1',
        paginate: 'api/v1/posts/paginated',
        crypto: 'api/v3/currencies'
    },
}